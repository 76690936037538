import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  ThreeScreensSlideInTimeline,
  ThreeImagesSlideInTimeline,
  GridStagger,
  FeatureParralax,
} from "./Timelines";

gsap.registerPlugin(ScrollTrigger);

export default class ScrollSections {
  #root;
  #wrapper = null;
  #timeline = null;
  #animationName = "";
  #animationElms = "";

  constructor(root) {
    this.#root = root;

    setTimeout(() => {
      this.#getElements();
      this.#setupTimeline(this.#animationName, this.#animationElms);
    }, 200);
  }

  #getElements() {
    this.#wrapper = this.#root;
    this.#animationName = this.#wrapper.dataset.triggerAnimation;
    this.#animationElms = [
      ...this.#wrapper.querySelectorAll(
        `.${this.#wrapper.dataset.animationElements}`
      ),
    ];
  }

  #setupTimeline(animationName, animationElms) {
    switch (animationName) {
      case "ThreeScreensSlideIn":
        this.#timeline = ThreeScreensSlideInTimeline(
          this.#wrapper,
          animationElms
        );
        break;
      case "ThreeImagesSlideIn":
      case "threeImagesOwners":
      case "threeImagesConsultant":
      case "threeImagesAdministrator":
      case "threeImagesPortfolio":
      case "threeImagesCo2Calculator":
      case "reports":
      case "notificationsHighlight":
      case "featureHighlightNativeApp":
      case "surveyorsReportHighlight":
      case "maintenancePlanHighlight":
      case "featureHighlightWhitelabel":
        this.#timeline = ThreeImagesSlideInTimeline(
          this.#wrapper,
          animationElms
        );
        break;
      case "GridStagger":
        this.#timeline = GridStagger(this.#wrapper, animationElms);
        break;
      case "FeatureParralax":
        this.#timeline = FeatureParralax(this.#wrapper, animationElms);
        break;
      default:
        console.error("Timeline not found for: ", animationName);
    }
  }
}
